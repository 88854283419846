@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Madimi+One&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --primary-color: #6949ff;
  --secondary-color: #ffc107;
  --primary-color-back: #6a49ff46;
  --secondary-color-back: #ffc1072c;
  --secondary-color-light: #f3f3f3;
  --secondary-color-dark: #231f20;
  --color-text-light: #ffffff;
  --color-text-dark: #000000;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
}

body {
  &.dark {
    --color-text: var(--color-text-light);
    background: var(--secondary-color-dark);
    --primary-color-special: var(--primary-color);
    --secondary-color-special: var(--secondary-color);
    --primary-color-special-back: var(--primary-color-back);
    --secondary-color-special-back: var(--secondary-color-back);
    --proress-bar-bg: var(--secondary-color-light);
    --background-color: rgba(255, 193, 7, 0.4);
    .index-sous-theme {
      .text-field-sous-theme {
        border-width: 0;
        border: 1px solid var(--primary-color);
        background-color: rgba(255, 193, 7, 0.4);
        border-radius: 10px;
        font-family: "Madimi One", sans-serif;
      }
    }
  }
  &.light {
    --color-text: var(--color-text-dark);
    background: var(--secondary-color-light);
    --primary-color-special: var(--secondary-color);
    --secondary-color-special: var(--primary-color);
    --primary-color-special-back: var(--secondary-color-back);
    --secondary-color-special-back: var(--primary-color-back);
    --proress-bar-bg: var(--secondary-color-dark);
    --background-color: rgba(105, 73, 255, 0.5);
    .index-sous-theme {
      .text-field-sous-theme {
        border-width: 0;
        border: 1px solid var(--secondary-color);
        background-color: rgba(105, 73, 255, 0.5);
        border-radius: 10px;
        font-family: "Madimi One", sans-serif;
      }
    }
  }

  .card-top-theme-home::-webkit-scrollbar {
    display: none;

    overflow-x: "auto";
    border: 1px solid var(--secondary-color);
    box-shadow: 0px 4px 8px rgba(105, 73, 255, 0.7);
  }
  .answer-text {
    font-family: "Madimi One", sans-serif;
    // font-size: 2rem; /* Taille par défaut de la police */

    // /* Définir différentes tailles de police pour différents breakpoints */
    // @media (max-width: 600px) {
    //   font-size: 1.2rem; /* Taille de police pour les écrans extra-small (xs) */
    // }
    // @media (min-width: 601px) and (max-width: 960px) {
    //   font-size: 1.7rem; /* Taille de police pour les écrans small (sm) */
    // }
    // @media (min-width: 961px) and (max-width: 1280px) {
    //   font-size: 2.5rem; /* Taille de police pour les écrans medium (md) */
    // }
    // @media (min-width: 1281px) {
    //   font-size: 3rem; /* Taille de police pour les écrans large (lg) et supérieurs */
    // }
  }
  /* Barre de défilement principale */
  ::-webkit-scrollbar {
    width: 8px; /* Largeur de la barre de défilement */
  }

  /* Bouton de la barre de défilement (flèches) */
  ::-webkit-scrollbar-button {
    background-color: var(--primary-color-special-back); /* Couleur du bouton */
  }

  /* Piste (fond de la barre de défilement) */
  ::-webkit-scrollbar-track {
    background-color: var(--secondary-color-special); /* Couleur de la piste */
  }

  /* Curseur de la barre de défilement */
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color-special); /* Couleur du curseur */
  }

  /* Coin en bas à droite de la barre de défilement */
  ::-webkit-scrollbar-corner {
    background-color: var(--secondary-color-special); /* Couleur du coin */
  }

  /* Barre de défilement en position "hover" */
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --primary-color-special
    ); /* Couleur du curseur au survol */
  }

  // footer.scss

  .footer-typography {
    cursor: pointer;
    font-family: "Madimi One", sans-serif;
  }

  .card-finish-private {
    background-color: var(--secondary-color-special);
    .card-content-finish-private {
      background-color: var(--primary-color-special);
    }
  }
  .text-camembert {
    color: var(--primary-color-special);
    font-family: "Madimi One", sans-serif;
  }
  .card-private-party {
    .opponent-txt-private-party {
      font-family: "Madimi One", sans-serif;
      color: var(--secondary-color-special);
    }
    .theme-txt-private-party {
      font-family: "Bangers", sans-serif;
      color: var(--primary-color-special);
      background-color: var(--secondary-color-special);
      box-shadow: 0px 4px 8px rgba(105, 73, 255, 0.7);
      border-radius: 5px;
    }
  }

  .politique h1,
  .politique h2,
  .politique h3,
  .politique h4,
  .politique h5,
  .politique h6 {
    color: var(--secondary-color-special);
  }
  .politique p {
    color: var(--color-text);
    font-weight: bold;
    font-family: "Madimi One", sans-serif;
  }

  .list-question {
    font-family: "Bangers", sans-serif;
    color: var(--primary-color-special);
    background-color: var(--secondary-color-special);
    box-shadow: var(--primaty-color-special);
    border-radius: 5px;
    font-weight: bold;
  }
  .text-no-theme {
    color: var(--secondary-color-special);
    font-family: "Bangers", sans-serif;
  }
  .question {
    font-family: "Madimi One", sans-serif;
    font-size: 2rem; /* Taille par défaut de la police */
    /* Définir différentes tailles de police pour différents breakpoints */
    @media (max-width: 600px) {
      font-size: 1.6rem; /* Taille de police pour les écrans extra-small (xs) */
    }
    @media (min-width: 601px) and (max-width: 960px) {
      font-size: 2rem; /* Taille de police pour les écrans small (sm) */
    }
    @media (min-width: 961px) and (max-width: 1280px) {
      font-size: 2rem; /* Taille de police pour les écrans medium (md) */
    }
    @media (min-width: 1281px) {
      font-size: 3rem; /* Taille de police pour les écrans large (lg) et supérieurs */
    }
  }

  main {
    min-height: calc(100vh - 199px);
  }
  .card-sent-friend {
    border: 1px solid var(--primary-color-special);
    box-shadow: 1px 1px 6px var(--secondary-color-special);
    border-radius: 20px;
    background-color: var(--secondary-color-dark);
    color: var(--primary-color-special);
  }
  .numb-pending-friend {
    font-family: "Madimi One", sans-serif;
  }
  .card-correction {
    border: 2px solid rgba(255, 193, 7, 0.4);
    box-shadow: 1px 1px 6px rgba(105, 73, 255, 1);

    .question-correction {
      font-family: "Bangers", sans-serif;
      color: var(--primary-color);
      -webkit-text-stroke: 1px rgba(255, 193, 7, 0.4); /* Ajoute une bordure autour de chaque lettre */
    }
    .text-answer-correction {
      font-family: "Madimi One", sans-serif;

      border-radius: 20;
    }
    .stack-answer-correction {
      font-family: "Madimi One", sans-serif;

      .text-good-answer-correction {
        font-family: "Madimi One", sans-serif;
      }
      .good-answer-correction {
        padding: "15px";
        background-color: rgba(255, 193, 7);
        font-family: "Bangers", sans-serif;
        color: var(--primary-color);
        border: 2px solid rgba(105, 73, 255, 0.6);
        box-shadow: 0px 1px 6px rgba(105, 73, 255, 1);
        -webkit-text-stroke: 1px var(--color-text-dark);
      }
    }
  }

  .card-part-end {
    background-color: var(--primary-color-special-back);
    border: 1px solid var(--primary-color-special);
    box-shadow: 0px 1px 6px var(--secondary-color-special);
    text-align: center; // Pour centrer le texte

    h4 {
      color: var(--secondary-color-special); // Couleur spécifique pour le titre
    }

    p {
      color: var(--text-color); // Couleur spécifique pour le texte
      font-weight: bold;
    }
  }

  /* General drawer styles */
  .drawer {
    width: 100%;
    .drawer-list {
      width: 100%;
      .drawer-item {
        display: flex; /* Ajoutez cela pour permettre la manipulation des éléments enfants en tant que flexbox */
        align-items: center; /* Cela va centrer verticalement le contenu */
        width: 100%;
        padding: 5px; /* Ajout de padding pour plus d'espace autour des éléments */
        .drawer-link {
          text-decoration: none;
          &.active {
            width: 100%;
            /* Utilisez border-bottom pour ajouter un soulignement de la même couleur que le fond */
            border-bottom: 2px solid var(--primary-color-special);
            color: var(--color-text);
          }
          .drawer-text {
            color: var(--secondary-color-special);
            font-family: "Madimi One", sans-serif !important;
            font-weight: bold !important; /* Ajoute cette ligne pour rendre le texte en gras */
          }
        }
      }
    }
  }

  .btn-log {
    font-family: "Bangers", sans-serif;
  }

  .form-question {
    border: 1px solid var(--secondary-color-special);
    background-color: var(--primary-color-special);
    box-shadow: 0px 1px 6px var(--secondary-color-special);
    border-radius: 20px;
    .title {
      color: var(--secondary-color-special);
    }
  }
  .content-new-request {
    border: 1px solid var(--secondary-color-special);
    background-color: var(--primary-color-special);
    box-shadow: 0px 1px 6px var(--secondary-color-special);
    border-radius: 20px;
  }
  .text-new-request {
    font-family: "Madimi One", sans-serif;
    color: var(--secondary-color-special);
  }
  .theme-new-request {
    background-color: var(--secondary-color-special);
    color: var(--primary-color-special);
    border-radius: 10px;
  }
  .btn-new-request {
    font-family: "Madimi One", sans-serif;
  }
  .content-new-request2 {
    border: 1px solid var(--primary-color-special);
    background-color: var(--secondary-color-special);
    box-shadow: 0px 1px 6px var(--primary-color-special);
    border-radius: 20px;
  }
  .text-new-request2 {
    font-family: "Madimi One", sans-serif;
    color: var(--primary-color-special);
  }
  .theme-new-request2 {
    background-color: var(--primary-color-special);
    color: var(--secondary-color-special);
    border-radius: 10px;
  }
  .btn-new-request2 {
    font-family: "Madimi One", sans-serif;
  }

  .form-connexion {
    .title-connexion {
      font-family: "Bangers", sans-serif;
      color: var(--secondary-color-special);
    }
    .input-connexion {
      font-family: "Madimi One", sans-serif;
      border-radius: 5px;
      box-shadow: 0px 1px 6px rgba(105, 73, 255, 0.8);
    }
    .btn-connexion {
      color: var(--primary-color);
      border: 2px solid rgba(105, 73, 255, 0.3);
      box-shadow: 1px 1px 6px rgba(105, 73, 255, 0.3);
    }
  }

  .custom-profil {
    .title-profil {
      color: var(--secondary-color-special);
    }
    .content-profile {
      border-radius: 20px;
      margin: auto;
      background-color: var(--primary-color-special-back);
      border: 2px solid var(--secondary-color-special);
      box-shadow: 1px 1px 6px var(--primary-color-special);

      .form-profile {
        padding: auto;
        .title-connexion {
          font-family: "Bangers", sans-serif;
          color: var(--secondary-color-special);
        }
        .input-profil {
          font-family: "Madimi One", sans-serif;
          border-radius: 5px;
          box-shadow: 0px 1px 6px var(--primary-color-special);
          margin-bottom: 16px; /* Ajout de marge en bas */
        }
        .btn-profil {
          color: var(--primary-color);
          border: 2px solid rgba(105, 73, 255, 0.3);
          box-shadow: 1px 1px 6px rgba(105, 73, 255, 0.3);
        }
      }
    }
  }
  .content-profile {
    border-radius: 20px;
    margin: auto;
    background-color: var(--primary-color-special-back);
    border: 2px solid var(--secondary-color-special);
    box-shadow: 1px 1px 6px var(--primary-color-special);

    .form-profile {
      padding: auto;
      .title-connexion {
        font-family: "Bangers", sans-serif;
        color: var(--secondary-color-special);
      }
      .input-profil {
        font-family: "Madimi One", sans-serif;
        border-radius: 5px;
        box-shadow: 0px 1px 6px var(--primary-color-special);
        margin-bottom: 16px; /* Ajout de marge en bas */
      }
      .btn-profil {
        color: var(--primary-color);
        border: 2px solid rgba(105, 73, 255, 0.3);
        box-shadow: 1px 1px 6px rgba(105, 73, 255, 0.3);
      }
    }
  }

  .regles {
    text-align: center;
    padding: 20px; /* Ajoutez un espacement intérieur pour aérer le contenu */
    .body {
      font-family: "Madimi One", sans-serif;
      color: var(--color-text);
      font-size: 1.3rem;
      margin-bottom: 20px; /* Ajoutez une marge en bas pour séparer les paragraphes */
      line-height: 1.5; /* Augmentez l'espacement entre les lignes pour une meilleure lisibilité */
    }
    h2,
    h1 {
      color: var(--secondary-color-special); /* Couleur du titre principal */
    }
  }

  .form-modif-user {
    border: 1px solid var(--primary-color-special);
    box-shadow: 1px 1px 6px var(--secondary-color-special);

    .input-pseudo {
      font-family: "Madimi One", sans-serif;
      border-radius: 5px;
      box-shadow: 0px 1px 6px var(--primary-color-special);
    }
    .btn-pseudo {
      color: var(--primary-color);
      border: 2px solid rgba(105, 73, 255, 0.3);
      box-shadow: 1px 1px 6px var(--primary-color-special);
    }
    .text-img-profil {
      font-family: "Bangers", sans-serif;
      color: var(--secondary-color-special);
      font-size: 1.2rem;
    }
    .text-img-select {
      font-family: "Madimi One", sans-serif;
      color: var(--color-text);
    }
    .text-btn {
      font-family: "Madimi One", sans-serif;
    }
    .box-image-profil {
      background-color: var(--primary-color-special);

      color: var(--secondary-color-special);
      .typo-image-profil {
        font-family: "Madimi One", sans-serif;
      }
    }
  }
  .border-correction {
    border: 2px solid rgba(105, 73, 255, 0.8);
    box-shadow: 1px 1px 6px var(--primary-color);
  }

  .box-correction {
    .title-correction {
      color: var(--primary-color);
      font-family: "Bangers", sans-serif;
      -webkit-text-stroke: 1px rgba(255, 193, 7, 0.6); /* Ajoute une bordure autour de chaque lettre */
    }
  }
  .text-btn {
    color: var(--primary-color-special);
    font-family: "Bangers", sans-serif !important;
  }
  .text-btn2 {
    color: var(--secondary-color-special);
    font-family: "Bangers", sans-serif !important;
  }
  .btn-custom {
    background-color: var(--primary-color-special);
    box-shadow: 1px 1px 6px var(--primary-color-special);
    border: 1px solid var(--secondary-color-special);
  }
  .btn-custom2 {
    background-color: var(--primary-color-special);
    box-shadow: 1px 1px 6px var(--secondary-color-special);
    border: 1px solid var(--primary-color-special);
  }
  .btn-confirmation {
    font-family: "Madimi One", sans-serif;
  }
  .completion-msg-card {
    background-color: rgba(255, 193, 7);
    border: 2px solid rgba(105, 73, 255, 0.8);
    box-shadow: 0px 1px 6px var(--primary-color);

    .title {
      color: var(--primary-color);
      font-family: "Bangers", sans-serif;
    }
    .text {
      color: var(--color-text-dark);
      font-family: "Madimi One", sans-serif;
    }
    .card {
      background-color: rgba(105, 73, 255, 0.4);
    }
    .text-score {
      color: var(--color-text-dark);
      font-family: "Madimi One", sans-serif;
    }
    .result {
      color: var(--primary-color);
      font-family: "Bangers", sans-serif;
      font-size: 1.8rem;
    }
    .btn-correction {
      font-family: "Madimi One", sans-serif !important;
      color: var(--color-text-dark);
    }

    .btn-correction:hover {
      filter: brightness(90%); /* Ajoute un filtre de luminosité au survol */
    }

    .btn-fermer {
      font-family: "Madimi One", sans-serif;
      color: var(--color-text-dark);
    }

    .btn-fermer:hover {
      filter: brightness(90%); /* Ajoute un filtre de luminosité au survol */
    }
  }
  .card-user-list-friend {
    background-color: var(--primary-color-special);
  }
  .theme-new-ranked {
    .card-theme-new-ranked {
      border: 1px solid var(--primary-color);
      border-radius: "20px";
      margin: "50px";
      .friend {
        border: 1px solid var(--primary-color-special);
      }
      .lancer-quiz {
        margin: "50px";
        color: #000000;
        font-size: 0.8rem;
        text-align: center;
        font-family: "Madimi One", sans-serif !important;
      }
      .title {
        color: var(--primary-color);
        font-family: "Bangers", sans-serif;
        margin: "10px";
        text-align: center;
        .text-with-shadow {
          position: relative;
          display: inline-block;
          -webkit-text-stroke: 1px rgba(255, 193, 7, 0.3); /* Bordure autour du texte */
        }

        .text-with-shadow::after {
          content: attr(data-content);
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          color: rgba(105, 73, 255, 0.9); /* Couleur de l'ombre */
          text-shadow: 2px 2px 4px rgba(105, 73, 255, 0.9); /* Définition de l'ombre portée */
        }
      }
      .card {
        width: "80%";
        border: 1px solid rgba(105, 73, 255, 0.4);
        background-color: #ffc107;
        box-shadow: 0px 1px 6px rgba(105, 73, 255, 0.5);
      }
      .text {
        font-family: "Madimi One", sans-serif;
        color: var(--color-text);
      }
      .label {
        font-family: "Bangers", sans-serif;
        color: var(--primary-color);
      }
      .level {
        font-family: "Bangers", sans-serif;
        color: var(--primary-color);
      }
      .bouton {
        font-family: "Madimi One", sans-serif;
        color: var(--color-text);
      }
      .bouton.disabled {
        filter: brightness(80%) hue-rotate(10deg);
      }

      .ul {
        padding: 0;
        list-style: none;
        // Utilisez flexbox pour centrer la liste
        text-align: center; // Centrez horizontalement les éléments de la liste
        margin-top: 10px; // Ajoutez une marge en haut pour l'espace
      }

      .li {
        padding: 3px; // Ajoutez un espacement autour des éléments de liste
        color: #000000;
        font-family: "Madimi One", sans-serif;
      }
    }
  }

  .new-ranked {
    background-color: rgba(255, 193, 7, 0.3);
    .card-new-ranked {
      border: 1px solid var(--primary-color);
      border-radius: "20px";
      margin: "50px";
      .lancer-quiz {
        margin: "50px";
        color: #000000;
        font-size: 0.8rem;
        text-align: center;
        font-family: "Madimi One", sans-serif !important;
      }
      .title {
        color: #000000;
        font-family: "Bangers", sans-serif;
        margin: "10px";
        text-align: center;
      }
      .sous-theme {
        margin: "50px";
        padding: 1;
        color: #000000;
        border-width: 0;
        border: 1px solid var(--primary-color);
        border-radius: 10px;
        background-color: rgba(105, 73, 255, 0.5);
        font-family: "Madimi One", sans-serif;
      }
      .bouton {
        font-family: "Madimi One", sans-serif;
        color: var(--color-text);
      }
      .ul {
        padding: 0;
        list-style: none;
        // Utilisez flexbox pour centrer la liste
        text-align: center; // Centrez horizontalement les éléments de la liste
        margin-top: 10px; // Ajoutez une marge en haut pour l'espace
      }

      .li {
        padding: 3px; // Ajoutez un espacement autour des éléments de liste
        color: #000000;
        font-family: "Madimi One", sans-serif;
      }
    }

    .card-new-ranked {
      border: 1px solid var(--primary-color);
      border-radius: "20px";
      margin: "50px";
      .lancer-quiz {
        margin: "50px";
        color: #000000;
        font-size: 0.8rem;
        text-align: center;
        font-family: "Madimi One", sans-serif !important;
      }
      .title {
        color: #000000;
        font-family: "Bangers", sans-serif;
        margin: "10px";
        text-align: center;
      }
      .sous-theme {
        margin: "50px";
        padding: 1;
        color: #000000;
        border-width: 0;
        border: 1px solid var(--primary-color);
        border-radius: 10px;
        background-color: rgba(105, 73, 255, 0.5);
        font-family: "Madimi One", sans-serif;
      }
      .bouton {
        font-family: "Madimi One", sans-serif;
        color: black;
      }
      .ul {
        padding: 0;
        list-style: none;
        // Utilisez flexbox pour centrer la liste
        text-align: center; // Centrez horizontalement les éléments de la liste
        margin-top: 10px; // Ajoutez une marge en haut pour l'espace
      }

      .li {
        padding: 3px; // Ajoutez un espacement autour des éléments de liste
        color: #000000;
        font-family: "Madimi One", sans-serif;
      }
    }
  }

  .top-theme-title {
    font-weight: "bold";
    color: var(--color-text);
    font-family: "Madimi One", sans-serif;
  }
  .btn-resultat {
    font-family: "Madimi One", sans-serif;
    color: var(--color-text);
  }
  .navbar_link {
    padding: 0 8px;
    font-family: "Madimi One", sans-serif;
    display: flex;
    position: relative;
    text-decoration: none;
    color: var(
      --color-text
    ); // Utilise la variable --color-text pour définir la couleur du texte
    align-items: center;

    &:hover::after,
    &.active::after {
      content: "";
      position: absolute;
      bottom: -28px;
      left: 0;
      right: 0;
      width: 100%;
      height: 5px;
      background-color: var(--primary-color);
    }

    &.active {
      color: var(--primary-color);
    }
  }

  .custom-link {
    color: var(--color-text-title) !important;
    text-decoration: underline; // Ajouter un soulignement
    // Ajouter d'autres styles personnalisés selon vos besoins
  }

  .custom-link:hover {
    color: var(--color-text) !important; // Changer la couleur au survol
  }
  .text-bar-question {
    color: var(--color-text);

    font-family: "Madimi One", sans-serif;
  }
  .red-text {
    color: red;
    font-family: "Bangers", sans-serif;
  }

  .card-new-ranking {
    background-image: url("../public/fonfVilot.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .card-new-prived {
    background-image: url("../public/fonfJaune.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .text-special {
    font-family: "Bangers", sans-serif;
    font-weight: bold;
    font-size: "3em";
  }

  .text-card-home {
    font-weight: "bold";
    font-family: "Madimi One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .top-theme-link {
    font-family: "Madimi One", sans-serif !important;
  }

  .text-top-home {
    font-family: "Madimi One", sans-serif;
  }
  .titre-theme {
    font-family: "Bangers", sans-serif;
    color: var(--primary-color);
  }
  .title-box-level {
    color: var(--secondary-color-special);
    font-family: "Bangers", sans-serif !important;
  }

  .box-image-profil {
    box-shadow: 0px 1px 6px var(--secondary-color-special);
    position: relative;
    border-radius: 10px;
    .input-image-profil {
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  .message-dialog {
    box-shadow: 0px 1px 6px var(--secondary-color-special);
    .message-title {
      background-color: var(--primary-color-special);
      color: var(--secondary-color-special);
      box-shadow: 0px 1px 6px var(--secondary-color-special);
    }
    .message-text {
      font-size: 1.5rem;
      color: var(--secondary-color-special);
      font-family: "Madimi One", sans-serif;
    }
    .message-btn {
      // color: var(--primary-color-special);
    }
  }
  .player-card {
    .player-info {
      .player-pseudo {
        color: var(--primary-color-special);
        font-family: "Madimi One", sans-serif;
      }
      .player-top-ranking {
        color: var(--color-text);
        font-family: "Madimi One", sans-serif;
      }
    }
  }

  .list-ranking {
    font-family: "Madimi One", sans-serif;
    border: 1px solid var(--secondary-color-special);
    border-radius: 10px;
    box-shadow: 0px 1px 6px var(--primary-color-special);
    background-color: var(--primary-color-special-back);

    .ranking-head {
      background-color: var(--primary-color-special);
      border: 1px solid var(--secondary-color-special);

      .MuiTableCell-root {
        font-family: "Bangers", sans-serif !important;
      }
    }

    .ranking-body {
      .ranking-row {
        font-family: "Madimi One", sans-serif;
        background-color: var(--primary-color-special-back);
        border: 1.2px solid var(--secondary-color-special);

        &:hover {
          font-family: "Madimi One", sans-serif;
          background-color: var(--secondary-color-special-back);
          border: 1.2px solid var(--secondary-color-special);
        }
      }

      .MuiTableCell-root {
        font-family: "Madimi One", sans-serif;
      }
    }
  }

  .stack-nav-list-multi {
    .btn-list {
      background-color: var(--primary-color);
      color: var(--color-text);
      border-radius: 50%;
      height: 60px;
      box-shadow: 0px 1px 6px rgba(255, 193, 7, 0.6);
    }
    .btn-multi {
      background-color: var(--secondary-color);
      color: var(--color-text);
      border-radius: 50%;
      height: 60px;
      box-shadow: 0px 1px 6px rgba(105, 73, 255, 0.5);
    }
  }
  .card-top-score {
    .text-ranking-score {
      font-family: "Bangers", sans-serif;
    }
    .text-stack-score {
      .text-stack-score {
        font-family: "Madimi One", sans-serif;
      }
      .text-score {
        font-family: "Bangers", sans-serif;
      }
      .text-stack {
        font-family: "Madimi One", sans-serif;
      }
    }
  }
  .container-result-profil {
    box-shadow: 0px 1px 6px var(--secondary-color-special);
    border: 1px solid var(--primary-color-special);
    max-width: "100%"; /* Ajuster automatiquement la largeur en fonction du contenu */
    overflow-x: auto; /* Assurer le défilement horizontal si le contenu dépasse */

    .cell-result-profil {
      font-family: "Bangers", sans-serif;
      color: var(--secondary-color-special);
    }

    .cell-other-profil {
      font-family: "Madimi One", sans-serif;
    }

    .head-result-profil {
      background-color: var(--primary-color-special);

      .label-result-profil {
        font-family: "Bangers", sans-serif;
      }
    }
  }
  .custom-profil {
  }

  .box-field {
    box-shadow: 0px 1px 6px var(--secondary-color-special);
  }
  .box-field input {
    font-family: "Madimi One", sans-serif;
  }
  .box-field label {
    font-family: "Madimi One", sans-serif;
    color: var(--secondary-color-special);
  }
}
